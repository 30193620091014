<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>please</b> do a Master Users (Create, update, delete)
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown
        size="sm"
        variant="link"
        toggle-class="custom-v-dropdown"
        no-caret
        right
        no-flip
        text="user_ids"
        v-b-tooltip.hover="'Add Data'"
      >
        <template v-slot:button-content>
          <a class="btn btn-icon" data-toggle="dropdown">
            <span class="svg-icon svg-icon-success svg-icon-2x">
              <inline-svg src="media/svg/icons/Files/File-plus.svg" />
            </span>
          </a>
        </template>
        <!-- Navigation -->
        <div class="navi navi-hover min-w-md-650px">
          <b-dropdown-text tag="div" class="navi-header font-weight-bold">
            Add Data Master User Name
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
            <a  class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-expand"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter your user name"
                v-model="add_data.user_name"
               
              />
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-tag"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter Email"
                v-model="add_data.user_email"
              />
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a  class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-list"></i>
              </span>
              <b-form-select  size="sm" v-model="selected" :options="options"></b-form-select>
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-tag"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter Level Password"
                v-model="add_data.user_password"
              />
            </a>
          </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-footer">
              <a
                class="btn btn-light-primary font-weight-bolder btn-sm" @click="saveData"
                >Save</a
              >
             
            </b-dropdown-text>
        </div>
        <!-- End Navigation -->
      </b-dropdown>
      </div>
    </b-alert>

    <div  class="card shadow-xs cardHover">
      <!-- Tabel -->
      <label ></label>
      <label ></label>
      <div class="form-group">
        <label for="name"></label>
        <b-form-group id="input-group-2"  label-for="input-2" class="mb-2 mr-sm-2 mb-sm-0">
       <b-form-input
         id="input-2"
         v-model="form.name"
         required
         placeholder="Search"
         class="mb-2 mr-sm-2 mb-sm-0"
       ></b-form-input>
     </b-form-group>

      </div>
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      > 
        <b-table
          id="my-table"
          :filter="form.name"
          striped hover :items="items"
          :per-page="perPage"
          :current-page="currentPage"
        >
        
        <template #cell(action)="row">
          <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown"
                  no-caret
                  dropup text="Drop-Up"
                  right
                  no-flip
                  v-b-tooltip.hover="'Update'"
                  ref="myDropdown"
                >
                  <template v-slot:button-content>
                    <a class="btn btn-icon" data-toggle="dropdown">
                      <i @click="updateItem(row.item)" class="card-title font-weight-bold text-muted text-hover-success flaticon2 flaticon2-pen">&nbsp; </i>

                    </a>
                  </template>
                  <!-- Navigation -->
                  <div class="navi navi-hover min-w-md-650px">
                    <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                      Upadte Data Question Type:
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                    <a  class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-expand"></i>
                      </span>
                      <b-form-input
                        size="sm"
                        placeholder="Enter your user name"
                        v-model="update_data.user_name"
                      />
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-text tag="div" class="navi-item">
                    <a class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-tag"></i>
                      </span>
                      <b-form-input
                        size="sm"
                        placeholder="Enter Email"
                        v-model="update_data.user_email"
                      />
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-text tag="div" class="navi-item">
                      <a  class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-list"></i>
                        </span>
                        <b-form-select  size="sm" v-model="selectedupdate" :options="options"></b-form-select>
                      </a>
                    </b-dropdown-text>
                  <b-dropdown-text tag="div" class="navi-item">
                        <a  class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-lock"></i>
                          </span>
                          <b-form-checkbox
                            v-for="option in optionsboxes_update"
                            v-model="checkboxes"
                            :key="option.value"
                            :value="option.value"
                            name="flavour-4a"
                            inline
                          >
                            {{ option.text }}
                          </b-form-checkbox>

                        </a>
                      </b-dropdown-text>
                  <b-dropdown-text v-if="checkboxes == 'A'" tag="div" class="navi-item">
                    <a class="navi-link">
                      <span class="navi-icon">
                      </span>
                      <b-form-input
                        size="sm"
                        placeholder="Enter your password"
                        v-model="update_data.user_password"
                      />
                    </a>
                  </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-footer">
                        <a
                        class="btn btn-light-primary font-weight-bolder btn-sm"  
                        @click="updateData"
                          >Update</a>
                      </b-dropdown-text>
                  </div>
                  <!-- End Navigation -->
          </b-dropdown>
          <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown"
                  no-caret
                  dropup text="Drop-Up"
                  right
                  no-flip
                  v-b-tooltip.hover="'Delete'"
                >
                  <template v-slot:button-content>
                    <a class="btn btn-icon" data-toggle="dropdown">
                      <i class="card-title font-weight-bold text-muted text-hover-danger flaticon2 flaticon2-trash">&nbsp; </i>
                    </a>
                  </template>
                  <!-- Navigation -->
                  <div class="navi navi-hover min-w-md-400px">
                    <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                      Delete Data:
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>

                    <b-dropdown-text tag="div" class="navi-item">
                      <a class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-warning"></i>
                        </span>
                        Are you sure you want to delete it?<br>
                      </a>
                      <h2>user name  {{ row.item.user_name }} </h2>
                    </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-footer">
                        <a
                        class="btn btn-light-primary font-weight-bolder btn-sm"  @click="deleteItem(row.item.action)"
                          >Delete</a
                        >
                      </b-dropdown-text>
                  </div>
                  <!-- End Navigation -->
                </b-dropdown>
        </template>
      </b-table>
      </b-alert>

      <!-- Pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        size="lg"
        class="mt-3"
        align="center"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      selected: null,
      criteria: "", // Properti filter
      fields: ['user_name', 'user_email', 'user_level', 'action'],
      items: [],
      perPage: 10,
      currentPage: 1,
      form: {
        name: '',
      },
      add_data: {
        user_name: '',
        user_email: '',
        user_level: '',
        user_password: '',
      },
      update_data: {
        user_name: '',
        user_email: '',
        user_level: '',
        user_password: '',
        user_id: '',
      },
      selected: null,
      selectedupdate: null,
      options: [
         { value: null, text: 'Please select Level User' },
         { value: 'Admin', text: 'Admin' },
         { value: 'User', text: 'User' },
       ],
      checkboxes: null,
      optionsboxes_update: [
         { text: 'Change Password', value: 'A' }
       ],
      show: true
    };
  },
  components: {
  },
  computed: {
     rows() {
       return this.items.length
     }
   },
  methods: {
    filterTable(row, filter) {
      // Ganti row.user_email sesuai dengan kolom yang ingin di-filter
      if (row.user_email.includes(filter)) {
        return true;
      } else {
        return false;
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.name = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  
    getMasterUsers() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetData(
          ApiService,
          `master/users`,
          response => {
            // Mengganti items dengan data dari API
            resolve(response.data);
            this.items = response.data.map(item => ({
              user_name: item.UserName,
              user_email: item.UserEmail,
              user_level: item.UserLevel,
              action: item.UserID
            }));

            // Resolve promise
          },
          err => {
            Swal.fire({
              title: "Data Not Found",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 1500
            });
            reject(err);
          }
        );
      });
    },

    saveData() {
      const formData = new FormData();
      formData.append("UserName", this.add_data.user_name);
      formData.append("UserEmail", this.add_data.user_email);
      formData.append("UserLevel", this.selected);
      formData.append("UserPassword", this.add_data.user_password);

      let contentType = `application/form-data`;

      Services.PostData(
        ApiService,
        `master/users/`,
        formData,
        contentType,
        // onSuccess function
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data saved successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            this.add_data.user_name = '';
            this.add_data.user_email = '';
            this.selected = '';
            this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.data.error,
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
        // onError function
        error => {
          console.error('Error during API call:', error);
          // Handle unexpected errors
        }
      )
    },

    updateItem(item) {
            this.update_data.user_email = item.user_email;
            this.selectedupdate = item.user_level;
            this.update_data.user_name = item.user_name;
            this.update_data.user_id = item.action;
    },

  updateData() {
        const formData = new FormData();
        formData.append("UserName", this.update_data.user_name);
        formData.append("UserEmail", this.update_data.user_email);
        formData.append("UserLevel", this.selectedupdate);
        formData.append("UserPassword", this.update_data.user_password);
        let contentType = `application/form-data`;

        Services.PutData(
          ApiService,
          `master/users/${this.update_data.user_id}`,
          formData,
          contentType,
          response => {
            if (response.status) {
              Swal.fire({
                title: "",
                text: "Data Update successfully.",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });

              this.update_data.user_name = '';
              this.update_data.user_email = '';
              this.update_data.user_level = '';
              this.update_data.user_id = '';
              this.loadData();
            } else {
              Swal.fire({
                title: "",
                text: response.error || 'Update failed',
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
          },
        )
        .finally(() => {
          // Hide the dropdown regardless of success or failure
          this.$nextTick(() => {
            if (this.$refs.myDropdown) {
              this.$refs.myDropdown.show = false;
            }
          });
        });
      },

      async deleteItem(index) {
      let contentType = `application/form-data`;

      Services.DeleteData(
        ApiService,
        `master/users/${index}`,
        contentType,
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data Delete successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.error || 'Delete failed',
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
      )
      .finally(() => {
        // Hide the dropdown regardless of success or failure
        this.$nextTick(() => {
          if (this.$refs.myDropdown) {
            this.$refs.myDropdown.show = false;
          }
        });
      });
      },

    loadData() {
      this.getMasterUsers()
        .then(() => {
          // Lakukan sesuatu setelah data dimuat
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/master" },
      { title: "User", route: "/user" }
    ]);
    this.loadData();
  }
};
</script>
<style>
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-group label {
  margin-right: 10px;
  flex-grow: 1; /* Optional: Add this to make sure the label takes up the available space on the left */
}
.form-group input {
  flex-grow: 2; /* Optional: Add this to make sure the input field takes up more space on the right */
}
</style>
